<template>
  <CustomBottomSheet
    :refName="'NotificationInfo'"
    size="xl"
    :headerText="$t('Notifications.data')"
    :headerIcon="notification.icon"
  >
    <div class="row">
      <DataLabelGroup
        :className="'col-md-9'"
        :value="notification.titleCurrent"
        :title="$t('title')"
        :imgName="'notifications.svg'"
      />
      <DataLabelGroup
        :className="'col-md-3'"
        :value="notification.dateTimeAgo"
        :title="$t('time')"
        :imgName="'time.svg'"
      />
      <DataLabelGroup
        :className="'col-md-12'"
        :value="notification.bodyCurrent"
        :title="$t('details')"
        :imgName="'notifications.svg'"
      />
    </div>
    <div class="form-actions">
      <div
        @click.prevent="closeBottomSheet('NotificationInfo')"
        class="icon-cancel"
      >
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../components/general/CustomBottomSheet.vue";
import { formateDateTimeLang } from "./../../../utils/functions";
import generalMixin from "./../../../utils/generalMixin";
import DataLabelGroup from "./../../../components/general/DataLabelGroup.vue";

export default {
  mixins: [generalMixin],
  components: {
    CustomBottomSheet,
    DataLabelGroup,
  },
  props: ["notification"],
  methods: {
    formateDateTimeLang,
  },
  async created() {},
};
</script>
